import React, { Component } from "react"
import { compile } from 'mathjs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { isBoolean, isNumber, isNaN } from "underscore"
import 'table2excel'
import axios from "axios"
import auth from '../../../utils/auth'
import cfg from '../../../utils/config'

pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    }
}

class Relatorio extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        carregando: false,
        modal: { status: false, msg: "" }
    }

    getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.setAttribute("crossOrigin", "anonymous")

            img.onload = () => {
                let canvas = document.createElement("canvas")
                canvas.width = img.width
                canvas.height = img.height

                let ctx = canvas.getContext("2d")
                ctx.drawImage(img, 0, 0)

                let dataURL = canvas.toDataURL("image/png")

                resolve(dataURL)
            }

            img.onerror = error => reject(error)

            img.src = url
        })
    }

    create_th_individual_pdf = () => {
        const promiseTh = new Promise((resolve, reject) => {
            let th_individual = []
            let width_table = []
            let th = this.props.detalhes.campos

            if (this.props.detalhes.periodoTodo) {
                th_individual.push({ text: "data_hora", alignment: 'center', fillColor: '#b7b7b7' })
                width_table.push("*")

                th.forEach((th, key_th) => {
                    th_individual.push({ text: th.identificacao, alignment: 'center', fillColor: '#b7b7b7' })
                    width_table.push("*")
                })

            } else {
                th_individual.push({ text: "data_hora_inicial", alignment: 'center', fillColor: '#b7b7b7' })
                th_individual.push({ text: "data_hora_final", alignment: 'center', fillColor: '#b7b7b7' })
                width_table.push("*", "*")

                th.forEach((th, key_th) => {
                    let escala = this.props.estrutura.escalas.filter((escala) => escala.identificacao == th.identificacao)[0].escala.value

                    th_individual.push({ text: th.imei + "_" + th.identificacao + "_inicial", alignment: 'center', fillColor: '#b7b7b7' })
                    th_individual.push({ text: th.imei + "_" + th.identificacao + "_final", alignment: 'center', fillColor: '#b7b7b7' })
                    width_table.push("*", "*")

                    if (escala) {
                        th_individual.push({ text: th.imei + "_" + th.identificacao + "_" + escala, alignment: 'center', fillColor: '#b7b7b7' })
                        width_table.push("*")
                    }
                })
            }

            resolve({ th_individual, width_table })
        })

        return promiseTh
    }

    create_td_individual_pdf = () => {
        const promiseTd = new Promise((resolve, reject) => {
            let prepare_data = []

            this.props.dataRelatorio.campos_usuarios.forEach((reporte, index) => {
                let reportes = []
                let th = []

                this.props.detalhes.campos.forEach((campo) => {
                    if (!this.props.detalhes.periodoTodo) {
                        let escala = this.props.estrutura.escalas.filter((escala) => escala.identificacao == campo.identificacao)[0].escala.value

                        let campo_inicial = { ...campo }
                        let campo_final = { ...campo }
                        let campo_escala = { ...campo }

                        campo_inicial['identificacao'] = campo_inicial.imei + "_" + campo_inicial.identificacao + "_inicial"
                        campo_final['identificacao'] = campo_final.imei + "_" + campo_final.identificacao + "_final"

                        th.push(campo_inicial)
                        th.push(campo_final)

                        if (escala) {
                            campo_escala['identificacao'] = campo_escala.imei + "_" + campo_escala.identificacao + "_" + escala
                            th.push(campo_escala)
                        }
                    } else {
                        th.push(campo)
                    }
                })

                th.forEach((th, key_th) => {
                    let value = reporte[th.identificacao] === null ? "--" : reporte[th.identificacao]
                    let toFixed = this.props.detalhes.campos.filter((campo) => {
                        if (th.identificacao.includes(campo.identificacao)) {
                            return campo
                        }
                    })

                    if (isNumber(value)) {
                        value = new Intl.NumberFormat(this.props.detalhes.separador.value, { minimumFractionDigits: toFixed[0]?.decimal ?? "" }).format(value)
                    }

                    if (key_th === 0) {
                        if (this.props.detalhes.periodoTodo) {
                            reportes.push({ text: reporte["data_hora"], alignment: 'center', fillColor: '#ffffff' })
                            reportes.push({ text: value, alignment: 'center', fillColor: '#ffffff' })
                        } else {
                            reportes.push({ text: reporte["data_hora_inicial"], alignment: 'center', fillColor: '#ffffff' })
                            reportes.push({ text: reporte["data_hora_final"], alignment: 'center', fillColor: '#ffffff' })
                            reportes.push({ text: value, alignment: 'center', fillColor: '#ffffff' })
                        }
                    } else {
                        reportes.push({ text: value, alignment: 'center', fillColor: '#ffffff' })
                    }


                })

                prepare_data.push(reportes)
            })
            resolve(prepare_data)
        })

        return promiseTd
    }

    createCabecalhoTable = (estrutura) => {
        let header = []
        let widths = []
        
        estrutura.colunasCabecalho.forEach((row, index_row) => {
            let columns = []
            
            row.forEach((column) => {
               
                columns.push(
                    { text: column.informacao, fillColor: 'white', colSpan: Number(column.colspan), rowSpan: Number(column.rowspan)}
                )
             
                if(index_row === 0) {
                    widths.push("*")
                }   
            })

            header.push(columns)
        })


        return {header, widths}
    }

    createGruposTable = (estrutura) => {
        let th_grupo = []

        estrutura.grupos.forEach((grupo, key) => {
            for (let i = 1; i <= grupo.colspan; i++) {
                th_grupo.push({ text: "", alignment: 'center', fillColor: '#ffffff' })
            }

            th_grupo.push({ text: grupo.descricao, alignment: 'center', fillColor: '#b7b7b7', colSpan: Number(grupo.th_colspan) })

            for (let i = 2; i <= grupo.th_colspan; i++) {
                th_grupo.push({})
            }
        })

        return th_grupo
    }

    createColunasTable = (estrutura, detalhes) => {
        let th_individual = []

        estrutura.colunas.forEach((coluna, key) => {
            if (key === 0 && detalhes.agruparDados.value != null) {
                th_individual.push({ text: 'Data', alignment: 'center', fillColor: '#b7b7b7' })
            }

            th_individual.push({ text: coluna.label, alignment: 'center', fillColor: '#b7b7b7' })
        })

        return th_individual
    }

    createDataTable = (estrutura, detalhes) => {
        let prepare_data = []
        this.props.dataRelatorio.campos_usuarios.forEach((data, key_data) => {
            let reporte = []

            estrutura.colunas.forEach((coluna, key_coluna) => {

                if (key_coluna === 0 && detalhes.agruparDados.value != null) {
                    let coluna_data = data.data_hora_inicial

                    if (detalhes.periodoTodo) {
                        coluna_data = data.data_hora
                    }

                    let text = this.transformData(detalhes.agruparDados.value, coluna_data)

                    reporte.push({ text: text ?? "--", alignment: 'center', fillColor: '#ffffff' })
                }

                if (coluna.formula_campo.value === "campo") {
                    let value = data[coluna.campo]
                    let toFixed = detalhes.campos.filter((campo) => {
                        if (coluna.campo.includes(campo.identificacao)) {
                            return campo
                        }
                    })


                    if (isNumber(value)) {
                        value = new Intl.NumberFormat(detalhes.separador.value, { minimumFractionDigits: toFixed[0].decimal }).format(value)
                    }

                    reporte.push({ text: value ?? "--", alignment: 'center', fillColor: '#ffffff' })

                } else if (coluna.formula_campo.value === "formula") {
                    try {
                        let formula = coluna.formula

                        for (let i in data) {
                            if (formula.includes(`[${i}]`)) {
                                formula = formula.replaceAll(`[${i}]`, data[i])
                            }
                        }

                        let value = compile(formula).evaluate()

                        if(isNaN(value)) {
                            value = "Resultado indefinido"
                        }

                        if (isNumber(value)) {
                            value = new Intl.NumberFormat(detalhes.separador.value, { minimumFractionDigits: 2 }).format(value)
                        }

                        reporte.push({ text: String(value), alignment: 'center', fillColor: '#ffffff' })
                    } catch {
                        reporte.push({ text: "Fórmula inválida", alignment: 'center', fillColor: '#ffffff' })
                    }
                } else if (coluna.formula_campo.value === "fixo") {
                    let value = coluna.fixo

                    reporte.push({ text: value ?? "--", alignment: 'center', fillColor: '#ffffff' })
                }
            })

            prepare_data.push(reporte)
        })

        return prepare_data
    }

    exportarPDF = async () => {
        let logo = true
        let infoEmpresa
        await axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/configuracao-sistema`, this.config_me).then(res => { infoEmpresa = res.data?.data })

        const estrutura = this.props.estrutura
        const detalhes = this.props.detalhes

        let prepare_header = []
        let prepare_data = []
        let width_table = []
        let data_cabecalho = []
        let width_cabecalho = []

        if (estrutura.grupos.length > 0 || estrutura.colunas.length > 0) {
            let th_grupo = []
            let th_individual = []

            if(estrutura.quantidadeLinhasCabecalho > 0 && estrutura.quantidadeColunasCabecalho > 0) {
                data_cabecalho = this.createCabecalhoTable(estrutura, detalhes).header
            }

            width_cabecalho = this.createCabecalhoTable(estrutura, detalhes).widths

            th_grupo = this.createGruposTable(estrutura)
            th_individual = this.createColunasTable(estrutura, detalhes)
            th_individual.forEach(() => width_table.push("*"))
            

            if (th_grupo.length <= width_table.length) {
                let quantidade = width_table.length - th_grupo.length

                for (let i = 1; i <= quantidade; i++) th_grupo.push({})
            } else {
                this.setState({
                    modal: {
                        status: true,
                        msg: "Algum grupo cadastrado está excedendo o limite de colunas!"
                    }
                })
                return
            }

            if (th_grupo.length > 0) {
                prepare_header.push(th_grupo)
            }

            prepare_header.push(th_individual)
            prepare_data = this.createDataTable(estrutura, detalhes)
            
        } else {
            let th_individual = []
            headerRows = 2

            await this.create_th_individual_pdf()
                .then((response) => {
                    th_individual = response.th_individual
                    width_table = response.width_table
                })

            prepare_header.push(th_individual)

            await this.create_td_individual_pdf()
                .then((response) => {
                    prepare_data = response
                })
        }

        let header = []
        let content = [
            {
                columns: [
                    {
                        width: '*',
                        text: 'Exportação de Dados',
                        margin: [0, 50, 0, 0],
                        fontSize: 18,
                        bold: true,
                    },
                    {
                        width: 360,
                        margin: [0, 0, 10, 0],
                        alignment: 'right',
                        text: [infoEmpresa?.email_contato, '\n', infoEmpresa?.endereco, '\n', infoEmpresa?.telefone, "\n"]
                    },
                    (logo) ? {
                        width: 100,
                        heigth: 100,
                        image: await this.getBase64ImageFromURL(document.querySelector('div.hdv-logomarca img').src),
                    } : null,
                ]
            },
            {
                text: ".",
                color: "white"
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        ["Relatório: " + this.props.detalhes.descricao + " - Período: " + this.props.detalhes.dataFormatada.replace('-', ' à ')],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                    },
                }
            },
            {
                text: ".",
                color: "white"
            },
            {
                text: ".",
                color: "white"
            },     
            {
                style: 'tableConsumo',
                color: '#444',
                fontSize: 8,
                table: {
                    widths: width_table,
                    body: [
                        ...prepare_header,
                        ...prepare_data
                    ]
                }
            }
        ]

        if(estrutura.quantidadeLinhasCabecalho > 0 && estrutura.quantidadeColunasCabecalho > 0) {
            content.splice(5, 0, 
                {
                    style: 'tableConsumo',
                    color: '#444',
                    fontSize: 8,
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: width_cabecalho,
                        body: data_cabecalho
                    }
                }
            )
          
        }

        let footer = []

        let fileDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 10, 10, 20],
            header: [header],
            content: [content],
            styles: { tableConsumo: { margin: [0, 0, 0, 0] }, },
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }


    exportarExcel = () => {
        const Table2Excel = window.Table2Excel

        const table2excel = new Table2Excel()
        table2excel.export(document.getElementById("table-relatorio"))
    }

    transformData = (agruparDados, hora_inicial) => {
        if (hora_inicial === "" || hora_inicial === undefined) {
            return "--"
        }

        let value = ""
        hora_inicial = hora_inicial.split(" ")

        switch (agruparDados) {
            case "hora":
                value = hora_inicial[0].replaceAll("-", "/") + "  " + hora_inicial[1]
                break
            case "dia":
                value = hora_inicial[0].replaceAll("-", "/")
                break
            case "mes":
                value = hora_inicial[0].replaceAll("-", "/").substring(3)
                break
            case "ano":
                value = hora_inicial[0].replaceAll("-", "/").substring(6)
                break
        }

        return value
    }

    render() {
        const style_table = {
            table: {
                borderCollapse: "collapse",
                tableLayout: "auto"
            },
            th: {
                textAlign: "center",
                background: "var(--fonte_titulo_abas)",
                color: "var(--fonte_menu_lateral)",
                verticalAlign: "middle"
            },
            th_cabecalho: {
                textAlign: "",
                background: "white",
                color: "black",
                verticalAlign: "baseline",
                width: "400px",
                border: "1px solid black"
            },
            th_individual: {
                background: "rgb(222 222 222)",
                textAlign: "center"
            }
        }

        return (
            <div style={{ display: this.props.edit ? "block" : "none" }}>
                <Modal isOpen={this.state.modal.status} toggle={() => this.setState({ modal: { status: false, msg: "" } })}>
                    <ModalHeader>
                        <span>Aviso</span>
                    </ModalHeader>
                    <ModalBody>
                        <strong>{this.state.modal.msg}</strong>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.setState({ modal: { status: false, msg: "" } })} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>

                <hr style={{ margin: "20px 0px 0px 0px" }} />

                <div style={{ display: 'flex', margin: "20px 0px" }} className="hdv-btn-group">
                    <button onClick={this.props.gerarRelatorio} className="hdv-btn-forms hdv-btn-blue">Gerar Relatório</button>
                    <button disabled={this.props.statusButton} onClick={this.exportarExcel} className="hdv-btn-forms hdv-btn-green">Exportar Excel</button>
                    <button onClick={this.exportarPDF} disabled={this.props.statusButton} className="hdv-btn-forms hdv-btn-red ">Exportar PDF</button>
                </div>

                <div className="row" style={{ overflow: "auto", marginLeft: "0px" }}>

                    {
                        this.props.relatorioGerado ?
                            this.props.dataRelatorio.campos_usuarios.length > 0 ?
                                this.props.estrutura != false && this.props.estrutura.grupos.length > 0 || this.props.estrutura != false && this.props.estrutura.colunas.length > 0 ?
                                    <>
                                        <table data-excel-name="Cabeçalho" style={{margin: "20px 0px", ...style_table.table }} className="table">
                                            <thead>
                                                {
                                                    this.props.estrutura.colunasCabecalho.map((linha, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                {
                                                                    linha.map((coluna, keyColuna) => {
                                                                        if(!coluna.disabled) {
                                                                            return(
                                                                                <th colSpan={coluna.colspan} rowSpan={coluna.rowspan} style={style_table.th_cabecalho} key={keyColuna} dangerouslySetInnerHTML={{ __html: coluna.informacao.replace(/\n/g, '<br />') }}></th>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </thead>
                                        </table>

                                        <table id="table-relatorio" data-excel-name="Dados" style={{ ...style_table.table }} className="table">
                                            <thead>
                                                {
                                                    this.props.estrutura.colunasCabecalho.map((linha, key) => {
                                                        return (
                                                            <tr style={{display: "none"}} key={key}>
                                                                {
                                                                    linha.map((coluna, keyColuna) => {
                                                                        if(!coluna.disabled) {
                                                                            return(
                                                                                <th colSpan={coluna.colspan} rowSpan={coluna.rowspan} style={style_table.th_cabecalho} key={keyColuna} dangerouslySetInnerHTML={{ __html: coluna.informacao.replace(/\n/g, '<br />') }}></th>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                <tr>
                                                    {
                                                        this.props.estrutura.grupos.map((grupo, key) => {
                                                            let th = []

                                                            for (let i = 1; i <= grupo.colspan; i++) {
                                                                th.push(<th style={{ borderStyle: "none" }} key={i + 1}></th>)
                                                            }

                                                            th.push(<th style={style_table.th} colSpan={grupo.th_colspan} key={key}>{grupo.descricao}</th>)

                                                            return th
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        this.props.estrutura.colunas.map((coluna, key) => {
                                                            if (key === 0 && this.props.detalhes.agruparDados.value != null) {
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        <th style={style_table.th_individual}>Data</th>
                                                                        <th style={style_table.th_individual}>{coluna.label}</th>
                                                                    </React.Fragment>
                                                                )
                                                            } else {
                                                                return <th style={style_table.th_individual} key={key}>{coluna.label}</th>
                                                            }

                                                        })
                                                    }
                                                </tr>

                                                {
                                                    this.props.dataRelatorio.campos_usuarios.map((data, key_data) => {
                                                        return (
                                                            <tr key={key_data}>
                                                                {
                                                                    this.props.estrutura.colunas.map((coluna, key_coluna) => {
                                                                        let coluna_data = ""

                                                                        if (key_coluna === 0 && this.props.detalhes.agruparDados.value != null) {
                                                                            let agruparDados = this.props.detalhes.agruparDados.value
                                                                            let hora_inicial = data.data_hora_inicial ?? ""

                                                                            if (this.props.detalhes.periodoTodo) {
                                                                                hora_inicial = data.data_hora ?? ""
                                                                            }

                                                                            let data_final = this.transformData(agruparDados, hora_inicial)

                                                                            coluna_data = <td title={data_final} style={{ textAlign: "center" }}>{data_final}</td>
                                                                        }

                                                                        if (coluna.formula_campo.value === "campo") {
                                                                            let value = data[coluna.campo] === undefined ? "--" : data[coluna.campo]
                                                                            let toFixed = this.props.detalhes.campos.filter((campo) => {
                                                                                if (coluna.campo.includes(campo.identificacao)) {
                                                                                    return campo
                                                                                }
                                                                            })


                                                                            if (isNumber(value)) {
                                                                                value = new Intl.NumberFormat(this.props.detalhes.separador.value, { minimumFractionDigits: toFixed[0].decimal }).format(value)
                                                                            }

                                                                            if (isBoolean(value)) {
                                                                                value = String(value)
                                                                            }

                                                                            return (
                                                                                <React.Fragment key={key_coluna}>
                                                                                    {coluna_data}
                                                                                    <td title={value ?? "--"} style={{ textAlign: "center" }} key={key_coluna}>{value ?? "--"}</td>
                                                                                </React.Fragment>
                                                                            )


                                                                        } else if (coluna.formula_campo.value === "formula") {
                                                                            try {
                                                                                let formula = coluna.formula

                                                                                for (let i in data) {
                                                                                    if (formula.includes(`[${i}]`)) {
                                                                                        formula = formula.replaceAll(`[${i}]`, data[i])
                                                                                    }
                                                                                }

                                                                                let value = compile(formula).evaluate()

                                                                                if(isNaN(value)) {
                                                                                    value = "Resultado indefinido"
                                                                                }

                                                                                if (isNumber(value)) {
                                                                                    value = new Intl.NumberFormat(this.props.detalhes.separador.value, { minimumFractionDigits: 2 }).format(value)
                                                                                }

                                                                                if (isBoolean(value)) {
                                                                                    value = String(value)
                                                                                }

                                                                                return (
                                                                                    <>
                                                                                        {coluna_data}
                                                                                        <td title={formula} style={{ textAlign: "center" }} key={key_coluna}>{value}</td>
                                                                                    </>
                                                                                )

                                                                            } catch {
                                                                                let value_error = "Fórmula inválida"

                                                                                return (
                                                                                    <>
                                                                                        {coluna_data}
                                                                                        <td title={value_error} style={{ textAlign: "center" }} key={key_coluna}>{value_error}</td>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        } else if (coluna.formula_campo.value === "fixo") {
                                                                            return (
                                                                                <>
                                                                                    {coluna_data}
                                                                                    <td title={coluna.fixo} style={{ textAlign: "center" }} key={key_coluna}>{coluna.fixo}</td>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                :   
                                    <table id="table-relatorio" style={{ ...style_table.table }} className="table">
                                        <thead>
                                            <tr>
                                                {
                                                    this.props.detalhes.campos.map((th, key_th) => {

                                                        if (this.props.detalhes.periodoTodo) {
                                                            if (key_th === 0) {
                                                                return (
                                                                    <>
                                                                        <th style={style_table.th_individual}>data_hora</th>
                                                                        <th style={style_table.th_individual}>{th.identificacao}</th>
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <th style={style_table.th_individual} key={key_th}>{th.identificacao}</th>
                                                                )
                                                            }
                                                        } else {
                                                            let escala = this.props.estrutura.escalas.filter((escala) => escala.identificacao == th.identificacao)[0].escala.value
                                                            let escalas = []

                                                            if (escala) {
                                                                escalas.push(
                                                                    <th style={style_table.th_individual}>{th.imei + "_" + th.identificacao + "_" + escala}</th>
                                                                )
                                                            }

                                                            if (key_th === 0) {

                                                                return (
                                                                    <>
                                                                        <th style={style_table.th_individual}>data_hora_inicial</th>
                                                                        <th style={style_table.th_individual}>data_hora_final</th>
                                                                        <th style={style_table.th_individual}>{th.imei + "_" + th.identificacao + "_inicial"}</th>
                                                                        <th style={style_table.th_individual}>{th.imei + "_" + th.identificacao + "_final"}</th>
                                                                        {[...escalas]}
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <th style={style_table.th_individual}>{th.imei + "_" + th.identificacao + "_inicial"}</th>
                                                                        <th style={style_table.th_individual}>{th.imei + "_" + th.identificacao + "_final"}</th>
                                                                        {[...escalas]}
                                                                    </>
                                                                )
                                                            }
                                                        }

                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.dataRelatorio.campos_usuarios.map((reporte, index) => {
                                                    let th = []

                                                    this.props.detalhes.campos.forEach((campo) => {
                                                        if (!this.props.detalhes.periodoTodo) {
                                                            let escala = this.props.estrutura.escalas.filter((escala) => escala.identificacao == campo.identificacao)[0].escala.value

                                                            let campo_inicial = { ...campo }
                                                            let campo_final = { ...campo }
                                                            let campo_escala = { ...campo }

                                                            campo_inicial['identificacao'] = campo_inicial.imei + "_" + campo_inicial.identificacao + "_inicial"
                                                            campo_final['identificacao'] = campo_final.imei + "_" + campo_final.identificacao + "_final"

                                                            th.push(campo_inicial)
                                                            th.push(campo_final)

                                                            if (escala) {
                                                                campo_escala['identificacao'] = campo_escala.imei + "_" + campo_escala.identificacao + "_" + escala
                                                                th.push(campo_escala)
                                                            }
                                                        } else {
                                                            th.push(campo)
                                                        }
                                                    })

                                                    return (
                                                        <tr key={index}>
                                                            {
                                                                th.map((th, key_th) => {
                                                                    let identificacao = th.identificacao

                                                                    let value = reporte[identificacao] === undefined ? "--" : reporte[identificacao]
                                                                    let toFixed = this.props.detalhes.campos.filter((campo) => {
                                                                        if (identificacao.includes(campo.identificacao)) {
                                                                            return campo
                                                                        }
                                                                    })


                                                                    if (isNumber(value)) {
                                                                        value = new Intl.NumberFormat(this.props.detalhes.separador.value, { minimumFractionDigits: toFixed[0]?.decimal ?? "" }).format(value)
                                                                    }

                                                                    if (isBoolean(value)) {
                                                                        value = String(value)
                                                                    }

                                                                    if (key_th === 0) {
                                                                        if (this.props.detalhes.periodoTodo) {
                                                                            return (
                                                                                <>
                                                                                    <td title={reporte["data_hora"]} style={{ textAlign: "center" }}>{reporte["data_hora"]}</td>
                                                                                    <td title={value} style={{ textAlign: "center" }}>{value}</td>
                                                                                </>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <>
                                                                                    <td title={reporte["data_hora_inicial"]} style={{ textAlign: "center" }}>{reporte["data_hora_inicial"]}</td>
                                                                                    <td title={reporte["data_hora_final"]} style={{ textAlign: "center" }}>{reporte["data_hora_final"]}</td>
                                                                                    <td title={value} style={{ textAlign: "center" }}>{value}</td>
                                                                                </>
                                                                            )
                                                                        }

                                                                    } else {
                                                                        return (
                                                                            <td title={value} style={{ textAlign: "center" }} key={key_th}>{value}</td>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                : <strong style={{ textAlign: "center", width: "100%", fontSize: "20px", marginTop: "20px" }}>Dados não encontrados!</strong>

                            : ""
                    }
                </div>
            </div>
        )
    }
}

export default Relatorio