const config = {
    //base_api_url: "http://3.236.75.204/",
    // base_api_url: "http://10.0.0.6:8000/",
    //base_api_url: "http://10.0.0.52:8000/",
    // base_api_url: "http://10.0.0.24:8000/",
    // base_api_url: "http://localhost:8000/",
    base_api_url: "https://api.afira.io/",
    api_version: "v1",
    api_v2: "v2",
    client_id: "6CiYtWNVxFxN28eS1VggRrGdpBouIGL3JYUKsNde",
    client_secret: "YCC7cebn6KgsnIiilouJddOlSjqP0gR6ebatZ5OQJHSLgiCGv2KvUvgadKJ92clx58B3IQSm52CPEaCgxcV4fIxCkBrVsXQZ1nswtIeQS3ZjnmTGSpmFPeOVjlpNVGxv",
    grant_type: "password"
}

export default config;  