import React, { Component } from 'react'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import * as rdrLocales from 'react-date-range/dist/locale'
import { DateRangePicker } from 'react-date-range'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import moment from 'moment'
import axios from 'axios'
import ValidarInformacoes from './validarInformacoes'


export default class Relatorio extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { data: "" },
        sideccr: { usuario: '', codigo: '', codigo_erro: '' },
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        validacao_formulario: { data: true },
        reporte: { reporte: [] },
        csv: { data: [], columns: [] },
        carregandoDados: false,
        relatorioGerado: false,
        dataFormatada: '',
        codigoOptions: [],
        tab: 0
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getData()
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhesDefault
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    handleChangeCodigoErro = (e) => {
        let { sideccr } = this.state

        sideccr['codigo_erro'] = e.target.value

        this.setState({sideccr})
    }

    showDatePicker = () => this.setState({ datePickerShow: !this.state.datePickerShow })

    manipularData = (sentido) => {
        if (!this.state.detalhesDefault.data)
            return

        let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime())
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        diffDays = (diffDays === 0) ? 1 : diffDays
        diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

        let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
        let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

        let detalhes = this.state.detalhes
        detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

        this.setState({
            dateRange: { startDate: newStartDate, endDate: newEndDate, key: 'selection' },
            dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    getData() {
        axios.get(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/sideccr/${this.props.match.params.id}`, this.config_me)
        .then(res => {
            let { sideccr } = this.state

            sideccr['usuario'] = res.data?.usuario
            sideccr['codigo'] = res.data?.codigo_medidor

            this.setState({ sideccr: sideccr })
        })
    }

    validacao = () => {
        const {validacao_formulario, detalhesDefault} = this.state

        validacao_formulario['data'] = detalhesDefault.data === '' ? false : true

        this.setState({ validacao_formulario })

        return validacao_formulario.data
    }

    buscarDadosRelatorio = async () => {
        const { detalhesDefault, sideccr } = this.state
      

        if(!this.validacao()) {
            return
        }

        this.setState({ carregandoDados: true, relatorioGerado: false })
        
        const formData = new FormData()
        let data = detalhesDefault.data.split('-')
        let data_inicio = data[0].split('/').join('-') + ' 00:00:00'
        let data_fim = data[1].split('/').join('-') + ' 23:59:59'

        sideccr.codigo_erro != "" ? formData.append('codigo_erro', sideccr.codigo_erro) : null  
        //formData.append('codigo_medidor', sideccr.codigo)
        //formData.append('usuario', sideccr.usuario)
        formData.append('data_inicial', data_inicio)
        formData.append('data_final', data_fim)


        await axios({
            method: 'POST',
            url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/sideccr/${this.props.match.params.id}/relatorio/`,
            data: formData,
            ...this.config_me,
        })
        .then(res => {
            let { reporte } = this.state
            let csv = { data: [], columns: [] }

            if (res.data && res.data?.reporte) {
                reporte = {
                    reporte: res.data.reporte ?? [],
                }

                if (reporte?.reporte?.length > 0) {
                    reporte?.reporte.map(dt => csv.data.push({
                        'data_fechado': this.convertToDate(dt.data_hora_fechado),
                        'data_utilizado': this.convertToDate(dt.data_hora_utlizado),
                        'vazao': Number(dt.vazao).toFixed(4),
                        'campo': dt.campo,
                        'resposta': dt.resposta_sideccr.mensagem_erro
                    }))
                }

                csv.columns = [
                    { 'id': 'data_fechado', 'displayName': 'Data fechado' },
                    { 'id': 'data_utilizado', 'displayName': 'Data utilizado' },
                    { 'id': 'vazao', 'displayName': 'Vazão' },
                    { 'id': 'campo', 'displayName': 'Campo' },
                    { 'id': 'resposta', 'displayName': 'Resposta' },
                ]
            }

            this.setState({ reporte: reporte, csv: csv, relatorioGerado: true })
        })
        .catch(err => console.log(err))

        this.setState({ carregandoDados: false })
    }

    convertToDate = timestamp => moment(timestamp * 1000).format('D/M H:mm:ss')

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    render() {
        const { relatorioGerado, reporte, sideccr, carregandoDados, validacao_formulario, detalhesDefault } = this.state

        return (
            <>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Relatório SIDECC-R</h4>
                            <div className="gray-background">
                                <i className="fa fa-network-wired fa-2x"></i>
                            </div>
                        </span>
                    </div>
                    <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                        <TabList>
                            <Tab>Principal</Tab>
                            <Tab disabled={detalhesDefault.data != "" ? false : true}>Validação</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="row col-md-12" style={{ padding: '0', margin: '0' }}>
                                <div className="col-md-5 hdv-top15" style={{ padding: '0' }}>
                                    <label style={{ margin: '0', fontWeight: 'bold', color: '#979797'}}>Periodo</label>
                                    <InputGroup className="hdv-force-hight">
                                        <InputGroupAddon addonType="prepend">
                                            <Button onClick={() => this.manipularData("voltar")} color="info">
                                                <i className="fa fa-angle-double-left"></i>
                                            </Button>
                                        </InputGroupAddon>
                                        <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                        <InputGroupAddon addonType="append">
                                            <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                                <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                                {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                            </Button>
                                        </InputGroupAddon>
                                        <InputGroupAddon addonType="append">
                                            <Button onClick={() => this.manipularData("avancar")} color="info">
                                                <i className="fa fa-angle-double-right"></i>
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {!validacao_formulario.data ? <span id='validacao-data'>Campo não pode ser nulo!</span> : null}

                                    <div className={this.state.datePickerShow ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                        <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                                    </div>
                                </div>

                                <div className="col-md-1"></div>
                                <div className="col-md-3 hdv-form-default">
                                    <label className="required">Usuário
                                        <input type="text" value={sideccr.usuario} readOnly disabled />
                                    </label>
                                </div>

                                <div className="col-md-3 hdv-form-default">
                                    <label className="required">Código do medidor
                                        <input type='text' value={this.state.sideccr.codigo} readOnly disabled/>
                                    </label>
                                </div>

                                <div className="col-md-3 hdv-form-default" style={{ padding: '0' }}>
                                    <label className="required">Código do erro (opcional)
                                        <input type='text' onChange={this.handleChangeCodigoErro} value={this.state.sideccr.codigo_erro}/>
                                    </label>
                                </div>

                                <div className="col-md-12 d-flex justify-content-end px-0 align-items-center py-1">
                                    <ReactLoading className={carregandoDados ? "hdv-report-loading pb-5" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
                                    <button
                                    style={{ backgroundColor: '#4da845', width: 'fit-content', padding: '12px 10px', margin: '12px 8px', borderRadius: '10px', border: 'none', cursor: 'pointer' }}
                                    className={reporte.reporte.length > 0 && relatorioGerado ? "" : "hdv-block-button mx-2 my-0"}>
                                    {
                                        relatorioGerado && this.state.csv.data.length > 0 ?
                                            <CsvDownloader
                                                style={{ border: 'none' }}
                                                columns={this.state.csv.columns}
                                                datas={this.state.csv.data}
                                                filename={`relatorio_sideccr_${new Date().getTime()}.csv`}
                                                separator=";">
                                                <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                                    Exportar CSV
                                                </p>
                                            </CsvDownloader>
                                        :
                                            <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                                Exportar CSV
                                            </p>
                                    }
                                    </button>

                                    <div style={{ display: 'flex', float: 'right' }}>
                                        <button
                                            style={{ width: 'fit-content' }}
                                            disabled={this.state.carregandoDados}
                                            className={this.state.carregandoDados ? "my-0 hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report my-0"}
                                            onClick={this.buscarDadosRelatorio} >Gerar Relatório
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            <div>
                                {reporte?.reporte?.length > 0 ?
                                    <div style={{margin: '20px 0px'}}>
                                        <table className="table afira-table">
                                            <thead>
                                                <tr>
                                                    <th>Vazão</th>
                                                    <th>Data fechado</th>
                                                    <th>Data utilizado</th>
                                                    <th>Campo</th>
                                                    <th>Timestamp</th>
                                                    <th>Resposta</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reporte?.reporte?.map((rep, index) => (
                                                    <tr key={index}>
                                                        <td hdv-title={rep.vazao}>{Number(rep.vazao).toFixed(4)}</td>
                                                        <td>{this.convertToDate(rep?.data_hora_fechado)}</td>
                                                        <td>{this.convertToDate(rep?.data_hora_utlizado)}</td>
                                                        <td>{rep.campo}</td>
                                                        <td>{rep.timestamp}</td>
                                                        <td>{rep.resposta_sideccr.mensagem_erro.substring(0, 3)} - {rep.resposta_sideccr.codigo_erro}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                : relatorioGerado ?
                                    <p style={{ textAlign: 'center', color: '#9c2f2f', fontWeight: 'bold' }}>
                                        Relatório não gerado
                                    </p>
                                : null}
                            </div>
                        </TabPanel>
                        <TabPanel>
                          <ValidarInformacoes
                            id={this.props.match.params.id}
                            data={detalhesDefault.data}
                          />
                        </TabPanel>
                    </Tabs>
                </div>
            </>
        )
    }
}
